.yard-and-display-screen-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  z-index: 1;
  margin-top: calc(var(--vh, 1vh) * -6);
}

.yard-and-display-screen_invite-friends {
  width: calc(var(--vh, 1vh) * 34);
  height: calc(var(--vh, 1vh) * 21);
  background-repeat: no-repeat;
  background-size: contain;
}

.yard-and-display-screen_popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  animation: 0.5s yard-and-display-screen_popup_enter;
}

@keyframes yard-and-display-screen_popup_enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.yard-and-display_subtitle {
  font-size: 40px;
  line-height: 1;
}

.yard-and-display_white-bg {
  position: absolute;
  width: 80%;
  height: 50%;
  background-size: cover;
  top: 0;
  background: white;
  margin-top: 10%;
  border-radius: 10px;
  box-shadow: rgb(99 99 99 / 20%) 6px 6px 0px 4px;
}

.yard-and-display_user-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--vh, 1vh) * 2);
}

.yard-and-display_camera-icon {
  position: absolute;
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
  background-size: contain;
  bottom: calc(var(--vh, 1vh) * 2);
  right: calc(var(--vh, 1vh) * 2);
}

.yard-and-display_buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 9);
}

.yard-and-display_required-players {
  color: #ba6f5c;
  font-size: 18px;
}

.yard-and-display_button-container {
  height: calc(var(--vh, 1vh) * 8);
  display: flex;
  justify-content: center;
  margin: 6px 0;
  width: 100%;
  /*min-width: 320px;*/
}

.yard-and-display-screen-picture-container {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 30);
  width: calc(var(--vh, 1vh) * 30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.yard-and-display-screen-picture {
  background-position: center;
  width: calc(var(--vh, 1vh) * 27);
  height: calc(var(--vh, 1vh) * 27);
  border-radius: 50%;
}

.yard-and-display-screen-button {
  width: 216px;
}

@keyframes yard-and-display-screen_text-activate-bottom {
  50% {
    transform: scale(1.3) rotate(-9deg);
  }
}

@keyframes yard-and-display-screen_text-activate {
  50% {
    transform: scale(1.3) rotate(5deg);
  }
}

.yard-and-display-screen_curved-text-bottom * {
  font-family: bebas-neue;
  transition: fill 0.5s;
}

.yard-and-display_hidden-dev-btn {
  position: absolute;
  top: 0;
  width: 100%;
  height: 10%;
}

.yard-and-display-test-text {
  color: red;
  font-size: 30px;
  margin-top: 20px;
  z-index: 1;
}

.yard-and-display_single-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 2.5);
  justify-content: space-evenly;
  flex-grow: 1;
}
.yard-and-display_hello-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 4);
  margin-top: calc(var(--vh, 1vh) * 2);
  margin-bottom: calc(var(--vh, 1vh) * 2);
}
.yard-and-display_hello {
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: calc(var(--vh, 1vh) * 2);
}

.yard-and-display_coop {
  margin-top: calc(var(--vh, 1vh) * 2);
  width: calc(var(--vh, 1vh) * 15);
  height: calc(var(--vh, 1vh) * 13);
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: calc(var(--vh, 1vh) * 4);
}

.yard-and-display_vs {
  margin-top: calc(var(--vh, 1vh) * 2);
  width: calc(var(--vh, 1vh) * 29);
  height: calc(var(--vh, 1vh) * 8);
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: calc(var(--vh, 1vh) * 2);
}

.yard-and-display_single-btn-container {
  display: flex;
  margin-top: calc(var(--vh, 1vh) * 4);
}
