.mic_header-root {
  z-index: 100;
  display: flex;
  position: absolute;
  left: 4vw;
  top: 2vh;
}

.mic_header-icon_container {
  margin-right: 1.5vw;
  width: 4vh;
  height: 4vh;
  border-radius: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic_header-icon {
  width: 4vh;
  height: 4vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mic_header-join {
}

.mic_header-leave {
}

.mic_header-grab {
}

.mic_header-mute {
}

.mic_header-unmute {
}
