.yard_screen-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.yard-tv_pc_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yard_screen-tv {
  width: calc(var(--vh, 1vh) * 30);
  height: calc(var(--vh, 1vh) * 27);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--vh, 1vh) * 10);
  transition: transform 0.3s, filter 0.3s;
}

.yard_screen-pc {
  width: calc(var(--vh, 1vh) * 30);
  height: calc(var(--vh, 1vh) * 27);
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s, filter 0.3s;
}

.yard_screen-tv:active,
.yard_screen-pc:active {
  transform: scale(0.9);
  filter: brightness(110%);
}

.yard-pc_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.yard-tv_download_icon {
  width: calc(var(--vh, 1vh) * 30);
  height: calc(var(--vh, 1vh) * 30);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--vh, 1vh) * 10);
}

.yard_screen-browser {
  width: 80vw;
  height: 23vw;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--vh, 1vh) * 10);
}

.yard_screen-pin_example {
  width: calc(var(--vh, 1vh) * 35);
  height: calc(var(--vh, 1vh) * 29);
  border-radius: calc(var(--vh, 1vh) * 1);
  background-size: contain;
  background-repeat: no-repeat;
  border: 3px solid black;
}

.yard_screen-pin {
  width: calc(var(--vh, 1vh) * 8);
  height: calc(var(--vh, 1vh) * 8);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: calc(var(--vh, 1vh) * 1);
}

.yard_screen-title {
  font-size: calc(var(--vh, 1vh) * 5);
  margin-top: calc(var(--vh, 1vh) * 2);
  margin-bottom: calc(var(--vh, 1vh) * 2);
  text-align: center;
}
